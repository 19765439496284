import { NgModule } from '@angular/core';
import { TransformObjectPipe } from "./transform-object.pipe";

@NgModule({
	declarations: [
		TransformObjectPipe
	],
	imports: [],
	exports: [
		TransformObjectPipe
	]
})
export class SharedPipeModule { }
