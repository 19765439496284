import {
	IsBoolean,
	IsEnum,
	IsNumber,
	IsNumberString,
	IsOptional,
	IsString, Max,
	Min,
	ValidateNested
} from 'class-validator';
import { Type } from 'class-transformer';
import { LayerDto, LayerType } from './layer.dto';

export enum ResizeMode {
	AUTO = 'auto',
	SCALE = 'scale',
	FIT = 'fit',
	FILL = 'fill',
	LIMIT = 'limit',
	CROP = 'crop',
	THUMB = 'thumb',
	MFIT = 'mfit',
	PAD = 'pad',
	LPAD = 'lpad',
	MPAD = 'mpad',
	LFILL = 'lfill',
	FILL_PAD = 'fill_pad'
}

export enum Gravity {
	AUTO = 'auto',
	NORTH_EAST = 'north_east',
	NORTH = 'north',
	NORTH_WEST = 'north_west',
	WEST = 'west',
	SOUTH_WEST = 'south_west',
	SOUTH = 'south',
	SOUTH_EAST = 'south_east',
	EAST = 'east',
	CENTER = 'center',
	XY_CENTER = 'xy_center'
}

export enum ZoomGravity {
	NORTH_EAST = 'north_east',
	NORTH = 'north',
	NORTH_WEST = 'north_west',
	WEST = 'west',
	SOUTH_WEST = 'south_west',
	SOUTH = 'south',
	SOUTH_EAST = 'south_east',
	EAST = 'east',
	CENTER = 'center'
}

export class LayerOptimizationResizeDto {
	@IsOptional()
	@IsEnum(ResizeMode)
	mode: ResizeMode;

	@IsOptional()
	@IsString()
	width: string;

	@IsOptional()
	@IsString()
	height: string;

	@IsOptional()
	@IsEnum(Gravity)
	gravity: Gravity;

	@IsOptional()
	@IsNumberString()
	x: string;

	@IsOptional()
	@IsNumberString()
	y: string;

	@IsOptional()
	@IsNumberString()
	zoom: string;

	@IsOptional()
	@IsString()
	zoomGravity: ZoomGravity;

	@IsOptional()
	@IsString()
	maxResolution?: string;
}

export class LayerOptimizationTargetCropDto {
	@IsOptional()
	@IsNumberString()
	tx: string; // Focal Area Box X

	@IsOptional()
	@IsNumberString()
	ty: string; // Focal Area Box Y

	@IsOptional()
	@IsNumberString()
	tw: string; // Focal Area Box Width

	@IsOptional()
	@IsNumberString()
	th: string; // Focal Area Box Height

	@IsOptional()
	@IsString()
	margin: string; // Focal Area Box Margin

	@IsOptional()
	@IsString()
	width: string;

	@IsOptional()
	@IsString()
	height: string;
}

export class LayerOptimizationETrimDto {
	@IsOptional()
	@IsBoolean()
	enabled: boolean;

	@IsOptional()
	@IsNumber()
	@Min(0)
	@Max(100)
	tolerance: number;
}

export class LayerOptimizationsDto {
	@IsOptional()
	@IsString()
	provider?: 'cloudinary' | 'tasker';

	@IsOptional()
	@IsString()
	quality?: string;

	@IsOptional()
	videoTrimming?: {
		startOffset?: number;
		endOffset?: number;
		duration?: number;
	};

	@IsOptional()
	@IsString()
	baseline?: string; // https://cloudinary.com/documentation/transformation_reference#bl_baseline

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerOptimizationResizeDto)
	resize?: LayerOptimizationResizeDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerOptimizationTargetCropDto)
	targetCrop?: LayerOptimizationTargetCropDto;

	@IsOptional()
	@IsString()
	advanced?: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerOptimizationETrimDto)
	eTrim: LayerOptimizationETrimDto;

	public static optimizeLayer(layer: LayerDto, bucketId?: string, taskerEngineUrl?: string) {
		let assetPath = layer[layer.type]?.assetPath || null;

		return {
			...layer,
			[layer.type]: {
				...layer[layer.type],
				assetPath: this.optimizeAsset(assetPath, layer.type, layer.optimizations, bucketId, taskerEngineUrl)
			}
		};
	}

	public static optimizeAsset(
		assetPath: string,
		type: LayerType,
		optimizations: LayerOptimizationsDto,
		bucketId?: string,
		taskerEngineUrl?: string
	) {
		let resizeConfig = optimizations?.resize;
		let qualityOptimization = optimizations?.quality ? `${optimizations?.quality}/` : '';
		let eTrimOptimization = optimizations?.eTrim;
		let zoomOptimization = '';
		let targetCropOptimization = '';
		let transformations = [];

		// console.log('Optimizing layer with', optimizations.provider);

		// Apply transformations. These are same for video and image
		if (resizeConfig?.mode) {
			// If either width or height is more than 2000px, let's resize the largest side to 2000px
			// and keep the aspect ratio.
			let width = Math.ceil(Number(resizeConfig.width || 0));
			let height = Math.ceil(Number(resizeConfig.height || 0));

			if (resizeConfig.maxResolution) {
				let maxResolution = Number(resizeConfig.maxResolution);

				// Determine the scale factor needed to resize the larger dimension to maxResolution
				let scale = Math.min(maxResolution / width, maxResolution / height);

				// Apply the scale to both dimensions, but only if scale is less than 1 (i.e., a reduction is needed)
				if (scale < 1) {
					width = Math.ceil(width * scale);
					height = Math.ceil(height * scale);
				}
			}

			if (resizeConfig?.width) {
				// Make sure there are no decimals in the width.  Let's round up always.
				transformations.push(`w_${width}`);
			} else {
				// If width is not provided, use element width
				transformations.push(`w_$element.width`);
			}

			if (resizeConfig?.height) {
				// Make sure there are no decimals in the height.  Let's round up always.
				transformations.push(`h_${height}`);
			} else {
				// If width is not provided, use element width
				transformations.push(`h_$element.height`);
			}

			if (resizeConfig?.mode) {
				transformations.push(`c_${resizeConfig.mode}`);
			}

			if (resizeConfig?.gravity) {
				transformations.push(`g_${resizeConfig.gravity}`);
			}

			if (optimizations.provider === 'tasker') {
				transformations.push(`fl_progressive`);
				transformations.push(`we_true`);
			}
		}

		if (type === 'video') {
			if (optimizations.videoTrimming) {
				let { startOffset, endOffset, duration } = optimizations.videoTrimming;
				if (startOffset) {
					transformations.push(`so_${startOffset}`);
				}
				if (endOffset) {
					transformations.push(`eo_${endOffset}`);
				}
				if (duration) {
					transformations.push(`du_${duration}`);
				}
			}
		}

		if (type === 'image') {
			if (resizeConfig?.gravity === 'xy_center' && resizeConfig.x !== undefined && resizeConfig.y !== undefined) {
				transformations.push(`x_${resizeConfig.x},y_${resizeConfig.y}`);
			}

			if (resizeConfig?.zoom) {
				// Ignore if zoom isn't a number.
				if (isNaN(Number(resizeConfig.zoom))) {
					console.warn('Zoom level must be a number.');
				} else if (Number(resizeConfig.zoom) <= 100) {
					console.warn('Zoom level must be greater than 100.');
				} else {
					let zoom = Number(resizeConfig.zoom) / 100;
					const cropSize = 1 / zoom; // The width and height of the cropped area
					const offset = 1 - cropSize;

					switch (resizeConfig.zoomGravity) {
						case ZoomGravity.NORTH:
							zoomOptimization = `x_${(offset / 2).toFixed(4)},y_0,w_${cropSize.toFixed(4)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.SOUTH:
							zoomOptimization = `x_${(offset / 2).toFixed(4)},y_${offset.toFixed(4)},w_${cropSize.toFixed(
								4
							)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.EAST:
							zoomOptimization = `x_${offset.toFixed(4)},y_${(offset / 2).toFixed(4)},w_${cropSize.toFixed(
								4
							)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.WEST:
							zoomOptimization = `x_0,y_${(offset / 2).toFixed(4)},w_${cropSize.toFixed(4)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.NORTH_EAST:
							zoomOptimization = `x_${offset.toFixed(4)},y_0,w_${cropSize.toFixed(4)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.NORTH_WEST:
							zoomOptimization = `x_0,y_0,w_${cropSize.toFixed(4)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.SOUTH_EAST:
							zoomOptimization = `x_${offset.toFixed(4)},y_${offset.toFixed(4)},w_${cropSize.toFixed(4)},h_${cropSize.toFixed(
								4
							)},c_crop/`;
							break;
						case ZoomGravity.SOUTH_WEST:
							zoomOptimization = `x_0,y_${offset.toFixed(4)},w_${cropSize.toFixed(4)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
						case ZoomGravity.CENTER:
						default:
							const centeredOffset = offset / 2;
							zoomOptimization = `x_${centeredOffset.toFixed(4)},y_${centeredOffset.toFixed(4)},w_${cropSize.toFixed(
								4
							)},h_${cropSize.toFixed(4)},c_crop/`;
							break;
					}
				}
			}

			if (eTrimOptimization?.enabled) {
				let eTrim: string = "e_trim";
				if (eTrimOptimization.tolerance >= 0 && eTrimOptimization.tolerance <= 100) {
					eTrim += `:${eTrimOptimization.tolerance}`;
				}
				transformations.push(eTrim);
			}
		}

		if (type === 'image' && optimizations?.targetCrop) {
			let targetCrop = optimizations.targetCrop;

			if (targetCrop?.tx && targetCrop?.ty && targetCrop?.tw && targetCrop?.th) {
				targetCropOptimization = `c_target`;
				targetCropOptimization += `,tx_${targetCrop.tx},ty_${targetCrop.ty},tw_${targetCrop.tw},th_${targetCrop.th}`;

				// Turn width and height into an aspect ratio.
				if (targetCrop.width && targetCrop.height) {
					const gcd = (...arr) => {
						const _gcd = (x, y) => (!y ? x : gcd(y, x % y));
						return [...arr].reduce((a, b) => _gcd(a, b));
					};

					const gcdResult = gcd(Number(targetCrop.width), Number(targetCrop.height));

					targetCropOptimization += `,ar_${Number(targetCrop.width) / gcdResult}:${Number(targetCrop.height) / gcdResult}`;
				} else {
					// If width and height are not provided, use the element width and height
					targetCropOptimization += `,ar_$element.ar`;
				}

				if (targetCrop?.margin) {
					targetCropOptimization += `,${targetCrop.margin}`;
				}

				// Set the mode to be cover if its not already in there.
				if (targetCropOptimization.indexOf('am_cover') === -1) {
					targetCropOptimization += ',am_cover';
				}
			}

			targetCropOptimization += '/';
			// console.log('Target crop optimization', targetCropOptimization);
		}

		// If there is an advanced string, remove everything else and use it.
		if (optimizations.advanced) {
			// Remove any starting and ending slashes.
			let advanced = optimizations.advanced.replace(/^\/|\/$/g, '');
			transformations = [advanced];
		}

		// Apply cloudinary optimizations to video and image type layers
		if (type === 'video' || type === 'image') {
			if (qualityOptimization || transformations.length > 0) {
				let finalUrl = assetPath;

				if (!assetPath) {
					console.error('Asset path is required for optimization.', assetPath);
					return assetPath;
				}

				if (optimizations?.provider === 'tasker') {
					if (!taskerEngineUrl) {
						console.error('A taskerEngineUrl environment variable is required for tasker optimization.', assetPath);
						return assetPath;
					}

					let joinedTransformations = `${transformations.join(',')}${transformations.length > 0 ? '/' : ''}`;
					finalUrl = `${taskerEngineUrl.replace(
						/\/$/,
						''
					)}/asset/${targetCropOptimization}${zoomOptimization}${joinedTransformations}${qualityOptimization}path/${assetPath}`;

					// console.log('Final URL', finalUrl);
				} else {
					// Use Cloudinary

					if (!bucketId) {
						console.error('Bucket ID is required for cloudinary optimization.', assetPath);
						return assetPath;
					}
					let joinedTransformations = `${transformations.join(',')}${transformations.length > 0 ? '/' : ''}`;

					// Recognize if the asset path is a cloudinary path.  If it is, don't use the fetch url.
					finalUrl = `https://res.cloudinary.com/${bucketId}/${type}/fetch/${targetCropOptimization}${zoomOptimization}${joinedTransformations}${qualityOptimization}${assetPath}`;

					// Don't use fetch is the asset path is already a cloudinary path.
					if (assetPath && assetPath.includes('res.cloudinary.com')) {
						// Insert the transformations right after the 'upload' part of the image path.
						const parts = assetPath.split('/');
						const uploadIndex = parts.findIndex(part => part === 'upload');

						let transformations = `/${targetCropOptimization}${zoomOptimization}${joinedTransformations}${qualityOptimization}`;
						// Detect if we have a baseline optimization and add it first.
						if (optimizations.baseline) {
							transformations = `bl_${optimizations.baseline}/${transformations}`;
						}

						parts.splice(uploadIndex + 1, 0, transformations);

						finalUrl = parts
							.join('/')
							// Replace any double slashes with a single slash, but not http:// or https://
							.replace(/([^:]\/)\/+/g, '$1');
					}
				}

				return finalUrl;
			}
		}

		return assetPath;
	}
}
