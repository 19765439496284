import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID, arrayUpsert, snapshotManager } from '@datorama/akita';
import { forkJoin, of, Subject, Subscription, switchMap } from 'rxjs';
import { catchError, map, tap, throttleTime } from 'rxjs/operators';
import { PublicEncodeJob } from '../../../../../../api/src/encode/encode-job.entity';
import { environment } from '../../../../environments/environment';
import { BaseEntityService } from '../base-entity.service';
import { QueryParams } from '../collection/collection.model';
import { GlobalQuery, GlobalService } from '../global';
import { EncodeJobObject } from '../global/global.model';
import { MusicQuery } from '../music';
import { DefaultResponse, FindResponse } from '../session';
import { StoryQuery } from '../story';
import { StorySegmentQuery } from '../story-segment';
import { VoiceoverQuery } from '../voiceover/voiceover.query';
import { AudioClip, EncodeJobRequest, EncodeJobRequestVideo, Project, ProjectFindDto, SortStrategy, SortString } from './project.model';
import { ProjectsQuery } from './projects.query';
import { ProjectDownload, ProjectPreview, State, Status } from '../../../../../../api/src/project/project.entity';
import { ProjectsStore } from './projects.store';
import { CartQuery } from '../cart/cart.query';
import { CartService } from '../cart/cart.service';
import { Survey } from '../survey/survey.model';
import { Clip } from '../clip';
import { buildOrderClipData } from '../../../shared/utils/createJobRequestUtil';
import { StoryFormatQuery } from '../story-format';
import { CartStore } from '../cart/cart.store';
import { CartItem, CartItemType } from '../cart/cart.model';
import { AddSyncingProviderDto } from '../../../../../../api/src/project/models/add-syncing-provider.dto';
import { UpdateSyncingProviderDto } from '../../../../../../api/src/project/models/update-syncing-provider.dto';
import { cloneDeep } from 'lodash';
import { PublicSyncingProvider } from '../../../../../../api/src/project/syncingProvider.entity';

@Injectable({ providedIn: 'root' })
export class ProjectsService extends BaseEntityService {
	public saveSnapshotSubject: Subject<void> = new Subject();
	public saveSnapshotSubscription: Subscription;
	private _snapshotSavingQueue = 0;

	constructor(
		private readonly projectsStore: ProjectsStore,
		private readonly cartStore: CartStore,
		private readonly cartQuery: CartQuery,
		private readonly cartService: CartService,
		private readonly http: HttpClient,
		private readonly storyQuery: StoryQuery,
		private readonly storyFormatQuery: StoryFormatQuery,
		private readonly storySegmentQuery: StorySegmentQuery,
		private readonly musicQuery: MusicQuery,
		private readonly voiceoverQuery: VoiceoverQuery,
		private readonly projectsQuery: ProjectsQuery,
		private readonly globalQuery: GlobalQuery,
		private readonly globalService: GlobalService
	) {
		super();

		this.saveSnapshotSubscription = this.saveSnapshotSubject
			.asObservable()
			.pipe(
				throttleTime(1000),
				tap(() => {
					this._saveSnapshot();
				})
			)
			.subscribe();
	}

	public get() {
		this.setLoading(true);

		return this.http.get<DefaultResponse<Project[]>>(`${environment.apiUrl}/projects`).pipe(
			map(response => ({
				...response,
				data: response.data.map(project => this.prepareForAkita(project))
			})),
			tap(response => {
				this.projectsStore.set(response?.data);
				this.setLoading(false);
			})
		);
	}
	public find({ author, search, page, focus, businessId, businessName, status, limit, sortBy }: QueryParams) {
		this.setLoading(true);

		const findReq: ProjectFindDto = {
			name: search || undefined,
			businessId: businessId || undefined,
			businessName: businessName || undefined,
			userId: author || undefined,
			status: status || undefined,
			vehicle: focus || undefined,
			sort: sortBy ? (sortBy.direction === 'ASC' ? SortStrategy.ASC : SortStrategy.DESC) : undefined,
			sortBy: sortBy ? (sortBy.id as SortString) : undefined
		};

		return this.http
			.post<FindResponse<Project>>(`${environment.apiUrl}/projects/find`, findReq, { params: { page, perPage: limit } })
			.pipe(
				map(response => ({
					totalResults: response.totalResults,
					data: response.results?.map(project => this.prepareForAkita(project))
				})),
				tap(response => {
					this.projectsStore.set(response?.data);
					this.setLoading(false);
				})
			);
	}

	// TODO: improve when filtering is properly added
	public findProjectsTableData({ author, search, page, focus, businessId, businessName, status, limit, sortBy }: QueryParams) {
		this.setLoading(true);

		const findReq: ProjectFindDto = {
			name: search || undefined,
			businessId: businessId || undefined,
			businessName: businessName || undefined,
			userId: author || undefined,
			status: status || undefined,
			vehicle: focus || undefined,
			sort: sortBy ? (sortBy.direction === 'ASC' ? SortStrategy.ASC : SortStrategy.DESC) : undefined,
			sortBy: sortBy ? (sortBy.id as SortString) : undefined
		};

		return this.http
			.post<FindResponse<Project>>(`${environment.apiUrl}/projects/findProjectsTableData`, findReq, { params: { page, perPage: limit } })
			.pipe(
				map(response => ({
					totalResults: response.totalResults,
					data: response.results?.map(project => this.prepareForAkita(project))
				})),
				tap(response => {
					this.projectsStore.set(response?.data);
					this.setLoading(false);
				})
			);
	}


	public findPendingReview() {
		return this.http
			.get<FindResponse<Project>>(`${environment.apiUrl}/projects/find/review`)
			.pipe(map(response => response.results?.map(project => this.prepareForAkita(project))));
	}

	public findRecent() {
		return this.http
			.get<FindResponse<Project>>(`${environment.apiUrl}/projects/find/recent`)
			.pipe(map(response => response.results?.map(project => this.prepareForAkita(project))));
	}

	public getOne(id: string, keepActiveCartItem: boolean = false, skipLoader = false, dehydratedData: boolean = false) {
		if (!skipLoader) {
			this.setLoading(true);
		}

		return this.http.get<DefaultResponse<Project>>(`${environment.apiUrl}/projects/${id}`).pipe(
			map(response => response?.data),
			switchMap(project => {
				this.projectsStore.upsert(id, project);

				if (project.version === '2') {
					let projectCartItems: CartItem[] = cloneDeep(project.cartItems) || [];
					let activeCartItem: CartItem = this.cartQuery.getActive();
					if (keepActiveCartItem) {
						if (!projectCartItems.find(ci => ci.id == activeCartItem.id)) {
							projectCartItems.push(activeCartItem);
						}
					}
					if (projectCartItems?.length && projectCartItems.length) {
						// check if items exist before setting them, to prevent a bug that happens when first
						// adding an image template and cart items don't exist yet
						return forkJoin([of(project), this.cartService.get(project.id, dehydratedData)]);
					}
				}

				return forkJoin([of(project), of([])]);
			}),
			map(([project]) => project),
			tap(() => {
				if (!skipLoader) {
					this.setLoading(false);
				}
			})
		);
	}

	public getPreview(id: string, encodeJobId: string) {
		return this.http
			.get<DefaultResponse<ProjectPreview>>(`${environment.apiUrl}/projects/preview/${id}/${encodeJobId}`)
			.pipe(map(response => response?.data));
	}

	public getDownloadInfo(id: string, encodeJobId: string) {
		return this.http
			.get<DefaultResponse<ProjectDownload>>(`${environment.apiUrl}/projects/download/${id}/${encodeJobId}`)
			.pipe(map(response => response?.data));
	}

	public add(businessId: string, name?: string, version?: string) {
		return this.http
			.post<DefaultResponse<Project>>(`${environment.apiUrl}/projects/add`, {
				businessId: businessId,
				name: name,
				version: version
			})
			.pipe(
				map(response => response?.data),
				tap(project => {
					this.projectsStore.add(project);
				}),
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					throw err;
				})
			);
	}

	public update(id: Project['id'], obj: Partial<Project>, sync: boolean = true) {
		this.projectsStore.update(id, obj);

		if (sync) {
			this.http
				.patch<DefaultResponse<Project>>(`${environment.apiUrl}/projects/${id}`, { ...obj })
				.subscribe(() => {
					this.projectsStore.update(id as any, {
						modified: new Date().toISOString()
					});
				});
		}
	}

	public remove(id: Project['id']) {
		return this.http
			.delete<DefaultResponse<Project['id']>>(`${environment.apiUrl}/projects/${id}`)
			.pipe(tap(() => this.projectsStore.remove(id)));
	}

	public aiEdit(input?: string, prompt?: string) {
		return this.http.post<any>(`${environment.apiUrl}/projects/ai/edit`, {
			input,
			prompt
		});
	}

	public setActive(id: Project['id']) {
		this.projectsStore.setActive(id);
	}

	public setActiveEncodeJob(job: PublicEncodeJob) {
		this.projectsStore.update({
			activeEncodeJob: job
		});
	}

	public addEncodeJobToProject(job: PublicEncodeJob, project: Project) {
		this.projectsStore.update(project.id, {
			encodeJobs: arrayUpsert(project.encodeJobs || [], job.id, job)
		});
	}

	public setLoading(state: boolean) {
		this.projectsStore.setLoading(state);
	}

	public saveSnapshot(force = false) {
		if (force) {
			this._saveSnapshot();
		} else {
			this.saveSnapshotSubject.next();
		}
	}
	/**
	 * Shadowed function that actually runs the request after being throttled
	 */
	private _saveSnapshot() {
		const snapshot = snapshotManager.getStoresSnapshot([
			'story-format',
			'story',
			'story-segment',
			'survey',
			'music',
			'UI/story-segment',
			'UI/story',
			'voiceover'
		]);
		if (snapshot) {
			let cartItem = this.cartQuery.getActive();
			if (cartItem) {
				cartItem = Object.assign(
					{
						metadata: {
							thumbnail: {
								assetPath: this.cartQuery.getVideoItemThumbnailUrlFromItemData(snapshot)
							},
							packageName: cartItem.name,
							dimensions: {
								width: snapshot['story-format']?.entities[snapshot['story-format']?.active]?.canvasWidth,
								height: snapshot['story-format']?.entities[snapshot['story-format']?.active]?.canvasHeight
							}
						}
					},
					cartItem as CartItem
				);

				this._snapshotSavingQueue++;
				if (this._snapshotSavingQueue > 1) {
					return; //wait for the previous snapshot to finish saving
				}

				if (cartItem.id.includes('temp-')) {
					this.cartService
						.add(cartItem.projectId, {
							...cartItem,
							dirty: undefined,
							itemData: snapshot
						})
						.subscribe(newCartItem => {
							this.cartService.setActive(newCartItem.id);
							this.cartService.remove(undefined, cartItem.id, true);

							if (this._snapshotSavingQueue > 1) {
								// need to save the last snapshot
								this._snapshotSavingQueue = 0;
								this._saveSnapshot();
							} else {
								this._snapshotSavingQueue = 0;
							}
						});
				} else {
					this.cartService
						.update(
							cartItem.projectId,
							cartItem.id,
							{
								name: cartItem.name,
								itemData: snapshot,
								metadata: cartItem.metadata
							},
							false,
							true
						)
						.subscribe(() => {
							if (this._snapshotSavingQueue > 1) {
								// need to save the last snapshot
								this._snapshotSavingQueue = 0;
								this._saveSnapshot();
							} else {
								this._snapshotSavingQueue = 0;
							}
						});
				}
			} else {
				this.update(this.projectsStore.getValue().active as string, {
					workspaceState: snapshot
				});
			}
		}
	}

	public loadSnapshot(snapshot: any) {
		// Remove some of the project state that we don't want coming in.
		const newSnapshot = {
			'UI/story-segment': snapshot['UI/story-segment'],
			story: {
				...this.storyQuery.getValue(),
				active: snapshot['story']?.active
			},
			'story-segment': {
				...this.storySegmentQuery.getValue()
			},
			'story-format': {
				...this.storyFormatQuery.getValue()
			},
			survey: snapshot['survey'],
			music: {
				...this.musicQuery.getValue(),
				active: snapshot['music']?.active,
				selectedMusic: snapshot['music']?.selectedMusic,
				activeOption: snapshot['music']?.activeOption
			},
			voiceover: {
				...this.voiceoverQuery.getValue(),
				selectedVoiceoverPackageConfig: snapshot['voiceover']?.selectedVoiceoverPackageConfig,
				selectedVoiceoverPackage: snapshot['voiceover']?.selectedVoiceoverPackage
			}
		};

		if (snapshot['UI/story']) {
			newSnapshot['UI/story'] = snapshot['UI/story'];
		}

		snapshotManager.setStoresSnapshot(newSnapshot);
	}

	private encodeJobObjecToRequest(order: EncodeJobObject, autoApprove: boolean = false) {
		const story = this.storyQuery.getActive();
		const video: EncodeJobRequestVideo = {
			duration: Math.min(
				order.segments.reduce((acc, cur) => (acc += cur.maximumDuration), 0),
				30
			), // TODO #34
			audio: order.music?.urls?.original,
			overlayData: this.storyQuery.getActiveWithUI().ui.overlays.find((o, i) => i === 0)?.snapshot,
			clipData: order.clips.map((clip: Clip, idx: number) => {
				return buildOrderClipData(clip, idx, order, story, this.storySegmentQuery);
			})
		};

		video.audioClipData = order.voiceovers.map((vo, index) => {
			let audioClipData: AudioClip = {
				path: vo.audio.urls.original,
				duration: vo.overrideDurationValidation ? vo.audio.duration : vo.targetDuration
			};

			if (!isNaN(Number(vo.startOffset)) && vo.startOffset !== null) {
				audioClipData.offset = vo.startOffset;
			} else if (!isNaN(Number(vo.endOffset)) && vo.endOffset !== null) {
				audioClipData.offset = video.duration - Math.abs(vo.endOffset);
			} else {
				audioClipData.offset = 0;
			}

			if (vo.fill && order.voiceovers[index - 1]?.overrideDurationValidation) {
				const overrideVO = order.voiceovers[index - 1];

				if (overrideVO.audio.duration > overrideVO.targetDuration) {
					audioClipData.offset += overrideVO.audio.duration - overrideVO.targetDuration;
				}
			}

			return audioClipData;
		});

		const request: EncodeJobRequest = {
			autoApprove,
			client: {
				id: this.globalQuery.getValue().settings.organizationSlug,
				name: this.globalQuery.getValue().settings.organizationName
			},
			meta: {
				date: new Date().toISOString()
			},
			videos: [video]
		};
		return request;
	}

	public requestPreview(order: EncodeJobObject) {
		const projectId = this.projectsQuery.getActiveId();
		const request = this.encodeJobObjecToRequest(order);

		return this.http.post(`${environment.apiUrl}/projects/${projectId}/request-preview`, request).toPromise();
	}

	public requestPreviewForCartItem(projectId: Project['id'], cartItemId: CartItem['id'], order: EncodeJobObject) {
		const request = this.encodeJobObjecToRequest(order);

		return this.cartService.requestPreview(projectId, cartItemId, request).toPromise();
	}

	public requestFinalRender(order: EncodeJobObject, autoApprove: boolean = false) {
		const projectId = this.projectsQuery.getActiveId();
		const request = this.encodeJobObjecToRequest(order, autoApprove);
		return this.http.post(`${environment.apiUrl}/projects/${projectId}/submit-job`, request).toPromise();
	}

	createEncodeJob(id: Project['id'], request: EncodeJobRequest) {
		return this.http.post<DefaultResponse<PublicEncodeJob>>(`${environment.apiUrl}/projects/${id}/submit-job`, request).toPromise();
	}

	checkEncodeJobStatus(id: string) {
		return this.http.get(`${environment.apiUrl}/encode/job/${id}`).toPromise();
	}

	checkoutProject(id: Project['id'], settings: any) {
		return this.http
			.post<DefaultResponse<Project>>(`${environment.apiUrl}/projects/${id}/checkout`, settings)
			.pipe(
				tap(response => {
					if (response.status === 'succeeded') {
						this.projectsStore.upsert(id, response.data);
						this.cartService.setCartItemsFromProjectId(id);
					}
				})
			)
			.toPromise();
	}

	downloadReceipt(id: string, hidePrice?: boolean) {
		let hidePriceQuery = false;
		if (hidePrice === true) {
			hidePriceQuery = true;
		}
		return this.http
			.get(`${environment.apiUrl}/projects/${id}/compose-receipt.pdf?cached=false&hidePrice=${hidePriceQuery}`, {
				responseType: 'blob',
				headers: { accept: 'application/pdf' }
			})
			.pipe(
				catchError(err => {
					this.globalService.triggerErrorMessage(undefined, 'There was an error downloading the receipt.');
					throw err;
				})
			);
	}

	downloadProjectDocument(id: string, hidePrice?: boolean) {
		let hidePriceQuery = false;
		if (hidePrice === true) {
			hidePriceQuery = true;
		}
		this.http
			.get(`${environment.apiUrl}/projects/${id}/compose-receipt.pdf?cached=false&hidePrice=${hidePriceQuery}`, {
				responseType: 'blob',
				headers: { accept: 'application/pdf' }
			})
			.subscribe((res: any) => {
				let url = window.URL.createObjectURL(res);
				let a = document.createElement('a');
				document.body.appendChild(a);
				a.setAttribute('style', 'display: none');
				a.href = url;
				a.download = 'GTB_Studio_Project_' + id + '_' + Math.floor(Date.now() / 1000) + '.pdf';
				a.click();
				window.URL.revokeObjectURL(url);
				a.remove();

				return res;
			});
	}

	/**
	 * Get the projects checkout information table
	 * @param id
	 * @returns Promise
	 */
	public getProjectCheckoutTable(id: ID) {
		return this.http.get(`${environment.apiUrl}/projects/checkout-table/${id}`).toPromise();
	}

	public getFocusVehicle(survey: Survey): string | undefined {
		return survey?.questions?.find(question => question => question.slug === 'vehicle-s-you-d-like-to-focus-on')?.value;
	}

	public addSyncingProvider(projectId: Project['id'], syncingProviderDto: AddSyncingProviderDto) {
		return this.http
			.post<DefaultResponse<PublicSyncingProvider[]>>(
				`${environment.apiUrl}/projects/${projectId}/syncing-providers`,
				syncingProviderDto
			)
			.pipe(
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					return of(null);
				})
			);
	}

	public fetchSyncingProvider(projectId: Project['id']) {
		return this.http
			.get<DefaultResponse<PublicSyncingProvider[]>>(`${environment.apiUrl}/projects/${projectId}/syncing-providers`)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded') {
						this.projectsStore.update(projectId, {
							syncingProviders: res.data
						});
					}
				}),
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					return of(null);
				})
			);
	}

	public updateSyncingProvider(
		projectId: Project['id'],
		providerId: PublicSyncingProvider['id'],
		syncingProviderDto: UpdateSyncingProviderDto
	) {
		return this.http
			.put<DefaultResponse<PublicSyncingProvider[]>>(
				`${environment.apiUrl}/projects/${projectId}/syncing-providers/${providerId}`,
				syncingProviderDto
			)
			.pipe(
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					return of(null);
				})
			);
	}

	public deleteSyncingProvider(projectId: Project['id'], providerId: PublicSyncingProvider['id']) {
		return this.http
			.delete<DefaultResponse<PublicSyncingProvider[]>>(`${environment.apiUrl}/projects/${projectId}/syncing-providers/${providerId}`)
			.pipe(
				tap(res => {
					if (res.status === 'succeeded') {
						this.projectsStore.update(projectId, {
							syncingProviders: res.data
						});

						this.globalService.triggerSuccessMessage(
							$localize`:Success Message@@externalCreativePackageLinkDeletedSuccessMessageText:External creative package link deleted successfully`
						);
					}
				}),
				catchError(err => {
					this.globalService.triggerErrorMessage(err);
					return of(null);
				})
			);
	}

	prepareForAkita(enrollmentPeriod: Project) {
		const obj: Project = {} as Project;

		if (enrollmentPeriod) {
			// Use to modify any API values to be more amenable to the front end.
			Object.keys(enrollmentPeriod).forEach(key => {
				switch (key) {
					default:
						obj[key] = enrollmentPeriod[key];
						break;
				}
			});
		}

		// Use for new variables that aren't part of the api values
		obj.ui = {};

		let story = obj?.workspaceState?.story;
		obj.ui.activeStoryTitle = story?.entities[story.active]?.title || '(None)';

		obj.ui.renderPath = obj.encodeJobs?.find(job => job.tag === 'final' && job.status === 'completed')?.encodeTasks[0]?.result?.path;

		obj.ui.activeFocusTitle = this.getFocusVehicle(obj.workspaceState?.survey?.entities[obj.workspaceState?.survey.ids[0]]) || '(None)';

		return obj;
	}

	updateProjectState(state: string) {
		let correctState: State = State.GET_STARTED;

		switch (state) {
			case 'get-started':
				correctState = State.GET_STARTED;
				break;
			case 'editor':
				correctState = State.EDIT_VIDEO;
				break;
			case 'music':
				correctState = State.EDIT_AUDIO;
				break;
			case 'review':
				correctState = State.REVIEW_CHECKOUT;
				break;
		}

		console.log(correctState);

		this.update(this.projectsQuery.getActiveId() as string, {
			state: correctState
		});
	}

	createNewVersion(state: string) {
		this.update(this.projectsQuery.getActive().id, { status: Status.NewVersion }, true);
		this.setActiveEncodeJob(undefined);
		this.globalService.setState(state);
	}
}

export function convertProjectStateToCartItems(project: Project): CartItem[] {
	return [
		{
			id: '',
			name: project.name,
			type: CartItemType.VIDEO,
			projectId: project.id,
			metadata: {},
			itemData: project.workspaceState,
			encodeJob: project.encodeJobs?.[0],
			approved: project.status === Status.Approved
		}
	];
}
