<div *ngIf="tools.includes(ImageActionType.TextToImage)" class="group mt-3 mb-3">
	<app-textarea-input
		label="Text to image Prompt"
		i18n-label="Text to image Prompt@@imageToolImageGenerationTextToImagePromptLabel"
		placeholder="Enter a prompt of what you want to generate."
		i18n-placeholder="Text to image Prompt Placeholder@@imageToolImageGenerationTextToImagePromptPlaceholder"
		[parentForm]="textToImageForm"
		[controlName]="'prompt'"
	></app-textarea-input>

	<button mat-flat-button class="block with-loading mt-3" (click)="replaceBackground()" [disabled]="loadingAction === ImageActionType.TextToImage">
		<span class="text" i18n="Generate Button@@imageToolImageGenerationGenerateButtonLabel">Generate</span>
		<span class="spinner"><mat-spinner diameter="20" color="accent"></mat-spinner></span>
	</button>
</div>
