import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MusicState, MusicStore } from './music.store';
import { Music } from './music.model';
import { catchError, tap } from 'rxjs/operators';
import { DefaultResponse } from '../session';
import { Asset, AssetQueryRequest } from '../global/global.model';
import { environment } from '../../../../environments/environment';
import { forkJoin } from 'rxjs';
import { ProjectsQuery } from "../project";
import { LocalizationService } from "../../services/localization.service";

@Injectable({ providedIn: 'root' })
export class MusicService {
	constructor(private musicStore: MusicStore,
				private projectsQuery: ProjectsQuery,
				private localizationService: LocalizationService,
				private http: HttpClient) {}

	public get(params: AssetQueryRequest) {
		this.musicStore.setLoading(true);

		const filters = {};
		// If we have a storyId, we want to filter by any survey criteria there.
		// If we have a segmentId, we want to filter by any filter criteria there as well.

		let httpParams = {
			provider: params.provider,
			path: params.path,
			type: 'music'
		};

		if (params.includeTags) {
			const language = this.localizationService.getCurrentLanguageFromLocaleCode();
			httpParams['includeTags'] = [params.includeTags, language];
		}
		if (params.excludeTags) {
			httpParams['excludeTags'] = params.excludeTags;
		}

		httpParams['projectId'] = this.projectsQuery.getActiveId();

		const queries = this.http
			.get<DefaultResponse<Asset[]>>(`${environment.apiUrl}/asset`, {
				params: httpParams
			})
			.pipe(
				tap(response => {
					this.musicStore.set(response.data);
				}),
				catchError(err => {
					console.log(err)
					throw err
				})
			);

		return forkJoin(queries).pipe(tap(() => this.musicStore.setLoading(false)));
	}

	set(music: Music[]) {
		this.musicStore.set(music);
	}

	upsert(id: string, music: Partial<Music>) {
		this.musicStore.upsert(id, music);
	}

	setSelectedMusic(music?: Music) {
		if (music?.id) {
			this.musicStore.update({ active: music.id, selectedMusic: music });
		}else {
			this.musicStore.update({ active: undefined, selectedMusic: undefined });
		}
	}

	updateState(state: Partial<MusicState>) {
		this.musicStore.update(state);

		// Reset the selected music if we're selecting to not have background music.
		if (state.activeOption === 'none') {
			this.setSelectedMusic(undefined);
		}
	}

	public removeAll() {
		this.musicStore.remove();
	}
}
