import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, Repository } from 'typeorm';
import { ConnectorConfig, DataSourceConnectorType, DataSourceContext, ItemDef, QueryParams, SortDef } from './models';
import { ConnectorConfigCSV } from './connectors/csv/models';
import { Business, PublicBusiness } from '../business/business.entity';
import { ConnectorConfigSmartSheet } from './connectors/smartsheet/models';
import { PublicSite, Site } from '../site/site.entity';
import { FilterDef } from "./models/filter.model";
import { ConnectorConfigNative } from './connectors/native/models';
import { DataStore } from './data-store/data-store.entity';
import { Crypt } from '../_core/crypt';
import { ConnectorConfigCloudinary } from './connectors/cloudinary/models';
import { ConnectorConfigPexels } from './connectors/pexels/models';
import { ConnectorConfigBria } from './connectors/bria/models';
import { ConnectorConfigStrapi } from "./connectors/strapi/models";

export type PublicDataSource = Pick<DataSource,
	'id' | 'name' | 'slug' | 'connectorType' | 'dataSourceLink' | 'siteId' | 'businessId'
> & {
	site?: PublicSite;
	business?: PublicBusiness;
	config?: {
		target: string;
		context?: DataSourceContext[];
		mapping?: ItemDef[];
		params?: QueryParams;
		columnSeparator?: string;
		environment?: string;
		sortableFields?: SortDef[];
		filters?: FilterDef[];
		previewPath?: string;
		tagPath?: string;
		accessToken?: string;
		attribution?: string;
	};
}

@Entity('dataSources')
export class DataSource {
	public dataStoreRepository?: Repository<DataStore>;

	constructor(value?: Partial<DataSource>, dataStoreRepository?: Repository<DataStore>) {
		for(const k in value) {
			this[k] = value[k];
		}
		this.dataStoreRepository = dataStoreRepository;
		if(this.config && !this.config.target?.length && this.connectorType === DataSourceConnectorType.Native) {
			this.config.target = this.id;
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({type: "text", unique: true})
	slug: string;

	@Column({ type: 'enum', enum: DataSourceConnectorType })
	connectorType: DataSourceConnectorType;

	@Column('text', { nullable: true })
	dataSourceLink: string;

	@Column('uuid', { nullable: false })
	siteId: string;
	@ManyToOne(
		() => Site,
		{
			onDelete: 'CASCADE',
			nullable: false
		}
	)
	@JoinColumn({ name: 'siteId' })
	site: Site;

	@Column('uuid', { nullable: true })
	businessId?: string;
	@ManyToOne(
		() => Business,
		{
			onDelete: 'CASCADE',
			nullable: true
		}
	)
	@JoinColumn({ name: 'businessId' })
	business?: Business;

	@Column('text', {
		name: 'config',
		nullable: true
	})
	_config: string;
	public get config(): ConnectorConfig | ConnectorConfigNative | ConnectorConfigCSV | ConnectorConfigSmartSheet | ConnectorConfigCloudinary | ConnectorConfigPexels | ConnectorConfigBria {
		const decrypted: ConnectorConfig = Crypt.decryptObject(this._config, this.businessId);
		// Target to this source's id if empty it's a native source.
		if(decrypted && !decrypted.target?.length && this.connectorType === DataSourceConnectorType.Native) {
			decrypted.target = this.id;
		}
		return decrypted;
	}
	public set config(value: ConnectorConfig | ConnectorConfigNative | ConnectorConfigCSV | ConnectorConfigSmartSheet | ConnectorConfigCloudinary | ConnectorConfigPexels | ConnectorConfigBria) {
		this._config = Crypt.encryptObject(value, this.businessId);
	}

	public toPublic() {
		const pub: Partial<PublicDataSource> = {
			id: this.id,
			name: this.name,
			slug: this.slug,
			dataSourceLink: this.dataSourceLink,
			connectorType: this.connectorType,
			siteId: this.siteId,
			businessId: this.businessId
		};

		let connectorTypeConfig = getDataSourceConnectorType(this.connectorType);

		if(this.site) {
			pub.site = new Site(this.site).toPublic();
		}

		if(this.business) {
			pub.business = new Business(this.business).toPublic();
		}

		// The config should specifically have keys whitelisted so
		// credentials are not leaked.
		if(this.config) {
			let conf = this.config;
			pub.config = {
				target: conf.target
			};

			if(conf.context) {
				pub.config.context = conf.context;
			}

			if(conf.mapping) {
				pub.config.mapping = conf.mapping;
			}

			if (conf.params) {
				pub.config.params = conf.params;
			}

			if (conf.sortableFields) {
				pub.config.sortableFields = conf.sortableFields;
			}

			if (conf.filters) {
				pub.config.filters = conf.filters || connectorTypeConfig?.filters;
			}

			if (conf.previewPath) {
				pub.config.previewPath = conf.previewPath;
			}

			if (conf.tagPath) {
				pub.config.tagPath = conf.tagPath;
			}

			if (!conf.attribution) {
				pub.config.attribution = connectorTypeConfig?.attribution;
			}

			if (conf['columnSeparator']) {
				pub.config.columnSeparator = conf['columnSeparator'];
			}
			if (conf['environment']) {
				pub.config.environment = conf['environment'];
			}
		}

		return pub as PublicDataSource;
	}
}

export function getDataSourceConnectorType(value: DataSourceConnectorType): ConnectorConfig {
	switch(value) {
		case DataSourceConnectorType.CSV:
			return new ConnectorConfigCSV();
		case DataSourceConnectorType.SmartSheet:
			return new ConnectorConfigSmartSheet();
		case DataSourceConnectorType.Cloudinary:
			return new ConnectorConfigCloudinary();
		case DataSourceConnectorType.Native:
			return new ConnectorConfigNative();
		case DataSourceConnectorType.Pexels:
			return new ConnectorConfigPexels()
		case DataSourceConnectorType.Strapi:
			return new ConnectorConfigStrapi();
		case DataSourceConnectorType.Bria:
			return new ConnectorConfigBria();
	}
}
