export enum Dimension {
	Pixels = 'pixels',
	Inches = 'inches',
	Centimeters = 'centimeters',
	Millimeters = 'millimeters',
	Points = 'points',
	Picas = 'picas'
}

export const ScaleTable: Record<Dimension, Record<Dimension, number>> = {
	[Dimension.Pixels]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 1,
		[Dimension.Centimeters]: 1,
		[Dimension.Millimeters]: 1,
		[Dimension.Points]: (3 / 4),
		[Dimension.Picas]: 1
	},
	[Dimension.Inches]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 1,
		[Dimension.Centimeters]: 2.54,
		[Dimension.Millimeters]: 25.4,
		[Dimension.Points]: 72,
		[Dimension.Picas]: 6
	},
	[Dimension.Centimeters]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.393701,
		[Dimension.Centimeters]: 1,
		[Dimension.Millimeters]: 10,
		[Dimension.Points]: 28.3465,
		[Dimension.Picas]: 2.3622
	},
	[Dimension.Millimeters]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.0393701,
		[Dimension.Centimeters]: 0.1,
		[Dimension.Millimeters]: 1,
		[Dimension.Points]: 2.83465,
		[Dimension.Picas]: 0.23622
	},
	[Dimension.Points]: {
		[Dimension.Pixels]: (4 / 3),
		[Dimension.Inches]: 0.0138889,
		[Dimension.Centimeters]: 0.0352778,
		[Dimension.Millimeters]: 0.352778,
		[Dimension.Points]: 1,
		[Dimension.Picas]: 0.0833333
	},
	[Dimension.Picas]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.166667,
		[Dimension.Centimeters]: 0.422667,
		[Dimension.Millimeters]: 4.22675,
		[Dimension.Points]: 12,
		[Dimension.Picas]: 1
	}
};

export class Dimensions {

	// Converts a width and height from one dimension to another.
	// If the original unit is provided and the `from` value is `Dimension.Pixels`, it will convert to that first, then to the target unit.
	// This is used for pixel to point conversions while maintaining the original, physical scale.
	public static convert(
		valueW: number,
		valueH: number,
		from: Dimension,
		to: Dimension,
		originalScale: number = 300,
		originalUnit?: Dimension
	): { width: number; height: number; originalScale?: number } {
		if(from === to) {
			return { width: valueW, height: valueH, originalScale };
		}

		const output = {
			width: this.convertOne(valueW, from, to, originalScale, originalUnit),
			height: this.convertOne(valueH, from, to, originalScale, originalUnit)
		};

		if(to === Dimension.Pixels || to === Dimension.Points) {
			return output;
		}

		console.log('originalScale', originalScale, this.getSacalingFactor(from, to));

		return {
			...output,
			originalScale: (from === Dimension.Points) ? originalScale : originalScale / this.getSacalingFactor(from, to)
		};
	}

	// Converts a single value from one dimension to another.
	// If the original unit is provided and the `from` value is `Dimension.Pixels`, it will convert to that first, then to the target unit.
	// This is used for pixel to point conversions while maintaining the original, physical scale.
	public static convertOne(value: number, from: Dimension, to: Dimension, originalScale: number = 300, originalUnit?: Dimension) {
		//console.log('convertOne', value, from, to, originalScale, originalUnit);
		
		if(from === to) {
			return value;
		}

		if(!originalScale) {
			originalScale = 300;
		}

		if(
			(
				(from === Dimension.Pixels && to === Dimension.Points)
				||
				(from === Dimension.Points && to === Dimension.Pixels)
			)
			&&
			!originalUnit
		) {
			originalScale = 1;
		}

		const scalingFactor = this.getSacalingFactor(from, to);
		let scaled = value;
		if(from !== Dimension.Pixels) {
		//if((from !== Dimension.Pixels && to !== Dimension.Points) && (from !== Dimension.Points && to !== Dimension.Pixels)) {
			scaled = value * originalScale;
		} else {
			//scaled = value / originalScale

			// If coming from pixels and an original unit is provided, convert to that first.
			// This helps when converting between physical and virtual units.
			if(originalUnit) {
				console.log('originalUnit', originalUnit);
				console.log(value, from, originalUnit, originalScale);
				const original = this.convertOne(value, from, originalUnit, originalScale);
				return this.convertOne(original, originalUnit, to, originalScale);
			}
		}

		if(to === Dimension.Pixels) {
			// If converting to points, attempt to maintain the original scale by converting to the original unit first.
			if(from === Dimension.Points) {
				if(originalUnit) {
					console.log('originalUnit', originalUnit);
					console.log('scalingFactor', scalingFactor, scaled, originalScale, originalUnit);
					let interpreted = this.getSacalingFactor(from, originalUnit);
					console.log(scaled * interpreted);
					scaled *= interpreted;

					console.log('Centimeters', this.convertOne(scaled, Dimension.Pixels, Dimension.Centimeters, originalScale));

				} else {
					console.log('scalingFactor', scalingFactor, scaled, originalScale);
					scaled *= scalingFactor;
				}
			}
			return Math.round(scaled);
		}

		return (scaled / originalScale) * scalingFactor;
	}

	public static getSacalingFactor(from: Dimension, to: Dimension) {
		if(from === to) {
			return 1;
		}

		return ScaleTable[from]?.[to] ?? 1;
	}
}
