import { Entity, Column, PrimaryGeneratedColumn, ManyToOne, JoinColumn, Index } from 'typeorm';
import { Project } from '../project/project.entity';
import { EncodeJob, PublicEncodeJob } from '../encode/encode-job.entity';
import { AssetDto } from '../asset/models/asset.dto';
import { IsOptional } from 'class-validator';
import { LocaleDto } from '../creative-unit-package/models/locale.dto';
import { Utils } from '../project/project.utils';
import { CreativeUnitPackage } from "../creative-unit-package/creative-unit-package.entity";

export const DEFAULT_CART_ITEM_NAME = '';

export enum CartItemType {
	AD_UNIT = 'creativeUnit',
	// AD_UNIT_OPTIMIZER = 'creativeUnitOptimizer',
	VIDEO = 'video'
}

export type PublicProjectCartItem = Pick<
	ProjectCartItem,
	'id' | 'name' | 'type' | 'projectId' | 'itemData' | 'metadata' | 'locale' | 'approved' | 'acceptedCreativePackageTermsDate'
> & {
	encodeJob?: PublicEncodeJob;
};

@Entity('projectsCartItem')
export class ProjectCartItem {
	constructor(value?: Partial<ProjectCartItem>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column({
		type: 'text',
		default: DEFAULT_CART_ITEM_NAME,
		nullable: false
	})
	public name?: string;

	@Column({
		type: 'enum',
		enum: CartItemType,
		default: CartItemType.AD_UNIT,
		nullable: false
	})
	public type: CartItemType;

	@Column('jsonb', { nullable: true })
	public itemData: any;

	@Column('uuid')
	public projectId: string;

	@ManyToOne(
		type => Project,
		project => project.cartItems,
		{
			eager: false,
			onDelete: 'CASCADE',
			nullable: false
		}
	)
	@JoinColumn({ name: 'projectId' })
	public project?: Project | Partial<Project>;

	@Column('uuid', { nullable: true })
	encodeJobId?: string;

	@ManyToOne(() => EncodeJob, {
		eager: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE'
	})
	@JoinColumn({ name: 'encodeJobId' })
	encodeJob?: EncodeJob;

	@Column('jsonb', { nullable: true })
	metadata?: Record<string, any>;

	@Column('jsonb', { nullable: true })
	source?: any;

	@Column('jsonb', { nullable: true })
	public thumbnail: AssetDto | null;

	@IsOptional()
	@Column('jsonb', { nullable: true })
	locale?: LocaleDto;

	@Column('boolean', { default: false })
	public deleted: boolean;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public modified: string;

	@Column('boolean', { nullable: true })
	public approved?: boolean;

	@Column('timestamptz', { nullable: true })
	public acceptedCreativePackageTermsDate?: string;

	public toPublic(dehydratedObject?: boolean, exclude?: Array<keyof PublicProjectCartItem>) {
		const pub: Partial<PublicProjectCartItem> = {
			id: this.id,
			name: this.name,
			type: this.type,
			projectId: this.projectId,
			metadata: this._buildMetadata(),
			approved: this.approved,
			locale: this.locale,
			acceptedCreativePackageTermsDate: this.acceptedCreativePackageTermsDate
		};

		if (this.encodeJob && !exclude?.includes('encodeJob')) {
			pub.encodeJob = new EncodeJob(this.encodeJob).toPublic();
		}

		pub.itemData = dehydratedObject ? {} : this.itemData;

		return pub as PublicProjectCartItem;
	}

	public _buildMetadata(): Record<string, any> {
		return {
			...Utils.getCartIemMetadata(this),
			...this.metadata
		};
	}
}
